














































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import SenderViewModel from '../viewmodels/sender'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    InformationDialog: () => import('./infomation-dialog.vue')
  }
})
export default class SenderSending extends Vue {
  @Inject() vm!: SenderViewModel

  wallet = walletStore

  copyHash(item) {
    navigator.clipboard.writeText(item.hash)
  }

  retry(item) {
    this.vm.retrySolanaSend(item)
  }
}
